import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello everyone! <br />
            I’m Jyotirmoy Dutta, but you can call me&nbsp;
            <span className="purple">Ratul. </span>I hail from
            <span className="purple"> Siliguri, India.</span>
            <br />
            And I hold a B.Sc in{" "}
            <span className="purple">Computer Science</span> from Siliguri
            College.
            <br />
            <br />
            I’m passionate about coding and technology, with a keen interest in
            developing innovative solutions and exploring new tech trends.
            Beyond programming, I enjoy a range of activities that enrich my
            life and creativity:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Mobile Photography
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
            <li className="about-activity">
              <ImPointRight /> Hangout with friends
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Don't Chap Spread Love!"{" "}
          </p>
          <footer className="blockquote-footer">Ratul</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
