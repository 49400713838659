import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import myportfolio from "../../Assets/Projects/myportfolio.png";
import socialtool from "../../Assets/Projects/socialtool.png";
import gossip from "../../Assets/Projects/gossip.png";
import attendifyCrm from "../../Assets/Projects/attendify-crm.png";
import attendifyMobile from "../../Assets/Projects/attendify-mobile.png";
import monamiCrm from "../../Assets/Projects/monami-crm.png";
import monamiMobile from "../../Assets/Projects/monami-mobile.png";
import invoicer from "../../Assets/Projects/invoicer-crm.png";
import tallyaddons from "../../Assets/Projects/tallyaddons.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gossip}
              isBlog={false}
              title="GossiP: Connecting Siliguri College"
              description="Welcome to Gossip, the ultimate college chat app! Connect, chat, and collaborate with fellow students effortlessly. Enjoy secure and seamless communication for a vibrant college community."
              demoLink="https://play.google.com/store/apps/details?id=com.socialtools.gossip"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={socialtool}
              isBlog={false}
              title="SocialtOOl"
              description="This application is used to download Videos and other content from major Socialmedia like Instagram, Facebook, Whatsapp & Whatsapp beside that It has a cool features of converting any file extensions according to your requirements."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://firebasestorage.googleapis.com/v0/b/socialtool-6987e.appspot.com/o/SocialtOOl_v-4.2.2.apk?alt=media&token=bb60f801-7d27-431e-8e23-c170f4d4db58"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={attendifyMobile}
              isBlog={false}
              title="Attendify Mobile App"
              description="The Attendify mobile app simplifies the attendance process by allowing employees to punch in and out with ease. It captures a photo and records the current location during each punch to ensure accuracy and accountability. Employees can also view their attendance history, request leaves, and check their pay slips directly from their mobile device."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://github.com/Pegasus1502/Calculator"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={monamiMobile}
              isBlog={false}
              title="Monami Mobile App"
              description="The Monami mobile app enables users to collect points effortlessly by scanning unique QR codes generated by the dashboard. Each scan automatically adds points to the user's account, making the redemption process seamless and engaging. Users can view their accumulated points, track their redemption history, and stay updated on available rewards directly from their mobile device. With Monami, both administrators and users experience a streamlined point management system that enhances engagement and simplifies reward tracking. The integration of real-time QR code scanning and comprehensive administrative controls ensures an efficient and user-friendly point redemption process."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://monami.tallyaddons.in/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={attendifyCrm}
              isBlog={false}
              title="Attendify-Dashboard"
              description="Attendify is a comprehensive HRMS application designed to streamline attendance management and enhance productivity. Available as both a mobile app for employees and a web-based CRM dashboard for admins, Attendify offers a suite of powerful features tailored to modern workplace needs.The Attendify CRM dashboard provides admins with a robust toolset for managing employee attendance and performance. Admins can track employees' live locations throughout the workday, generate detailed pay slips, handle leave requests and approvals, and assign tasks efficiently. The dashboard offers comprehensive insights into attendance patterns and productivity metrics, empowering admins to make informed decisions and streamline HR processes."
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://attendify.tallyaddons.in/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={myportfolio}
              isBlog={false}
              title="My Portfolio"
              description="My portfolio website build with React framework."
              //  ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://Socialtool.co.in"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={invoicer}
              isBlog={false}
              title="Invoice Generator"
              description="The Embee Builders Proforma Invoice Generator simplifies the creation and delivery of maintenance invoices for flats. Generate detailed proforma invoices quickly and send them directly to customers via WhatsApp and email. This tool ensures efficient, professional billing and seamless communication with clients."
              demoLink="https://invoicer.tallyaddons.in/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={monamiCrm}
              isBlog={false}
              title="Monami Dashboard - Redeem Code"
              description="Monami is an innovative point redemption management application designed to streamline the process of generating and tracking reward points. Available as a web-based CRM dashboard for administrators and a mobile app for users, Monami offers an efficient solution for managing and redeeming points.The Monami CRM dashboard provides administrators with a powerful platform to generate and manage reward points. From the dashboard, admins can create and configure point systems, monitor point transactions, and oversee the overall point accumulation process. The intuitive interface allows for easy tracking and management of points, ensuring smooth operations and accurate record-keeping."
              demoLink="https://monami.tallyaddons.in/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tallyaddons}
              isBlog={false}
              title="Tally Addons"
              description="At TallyAddons.in, we provide bespoke Tally customizations tailored to your e-commerce needs. We offer specialized TDL and TCP files to enhance your Tally software’s functionality, ensuring seamless integration with your business processes. Our services include customized solutions and efficient file delivery for optimal performance."
              demoLink="https://tallyaddons.in/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
