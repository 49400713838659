import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiAndroidstudio,
  SiFigma,
  SiPostman,
  SiGooglechrome,
  SiBootstrap,
  SiVite,
  SiExpress,
  SiNpm,
  SiFirebase,
  SiVercel,
} from "react-icons/si";

import { DiGit } from "react-icons/di";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {/* Development Tools & IDEs */}
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAndroidstudio />
      </Col>

      {/* Design & Prototyping Tools */}
      <Col xs={4} md={2} className="tech-icons">
        <SiFigma />
      </Col>

      {/* Frontend Frameworks */}
      <Col xs={4} md={2} className="tech-icons">
        <SiBootstrap />
      </Col>

      {/* APIs & Testing Tools */}
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>

      {/* Build Tools */}
      <Col xs={4} md={2} className="tech-icons">
        <SiVite />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNpm />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiExpress />
      </Col>

      {/* Deployment Platforms */}
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
      </Col>

      {/* Miscellaneous */}
      <Col xs={4} md={2} className="tech-icons">
        <SiGooglechrome />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
    </Row>
  );
}

export default Toolstack;
